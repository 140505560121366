jQuery( document ).ready(function($) {
    $('.newsletter-contest').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('#maincontent').html('<a href="https://presaves.sonymusicfans.com/?campaign_id=216764&mailing_list_ids=a0S61000001YpluEAC&retargeting_consent=0&source_channel=Spotify" class="btn">Connect with Spotify</a> <p class="note">By connecting via Spotify you agree to receive news from Wolf Alice and Sony Music. For more information on how we use your data, please see the links below.</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        console.log('submit');
        const DATA = $(this).serialize();
    
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    // Spotify
    const SMESpotifyPreSave = {
        state: null,
        spotify_user: null,
        url_vars: [],
        getURLVars: function (callback, form_id) {
            let hash;
            const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

            for (let i = 0; i < hashes.length; i++)
            {
                hash = hashes[i].split('=');
                this.url_vars.push(hash[0]);
                this.url_vars[hash[0]] = hash[1];
            }

            this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
            this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
            this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;

            if (typeof form_id !== 'undefined' && this.spotify_user !== null) {
                const form = document.getElementById(form_id);
                form.innerHTML = form.innerHTML + '<input type="hidden" id="spotify_user" name="spotify_user" value="' + this.spotify_user + '" />';
            }

            if (typeof callback === 'function') {
                callback(this.state, this.spotify_user, this.message);
            }
        }
    };

    SMESpotifyPreSave.getURLVars(function(state) {
        if(state === 'thank-you' || state === 'success') {
            $('#maincontent').html('<p>Thank You For Entering</p>');
        }
    });
    
});